import React from 'react';
import Banner2 from '../components/Banner2';
import CasesBanner from '../images/cases-banner-2.svg';
import Layout from '../components/Layout';
import TitledBoxesWithBg from '../components/TitledBoxesWithBg';
import CasesPain1 from '../images/pain-2.svg';
import CasesPain2 from '../images/cases-pain-2.svg';
import CasesPain3 from '../images/cases-pain-13.svg';
import CasesPain4 from '../images/cases-pain-14.svg';
import StepSolutions from '../components/StepSolutions';
import Step1 from '../images/step_4_1.svg';
import Step2 from '../images/step_4_2.svg';
import Step3 from '../images/step_4_3.svg';
import Step4 from '../images/step_4_4.svg';
import StepsTopper from '../images/steps-topper-4.png';

const boxDataList = [
  {
    title: '电商数据未打通整合',
    content: `电商数据缺失导致难以综合评估渠道效果；\n难以捕捉客户的购买习惯和频次，无法全面了解客户`,
    icon: CasesPain1,
    id: 'cases-1',
    bgColor: 'white',
  },
  {
    title: '难以快速且精细地分层客群',
    content: `以往的分层都依靠人工进行，效率低且准确率低；\n分层颗粒度粗，很难依据组合条件分层`,
    icon: CasesPain2,
    id: 'cases-2',
    bgColor: 'yellow',
  },
  {
    title: '差异化营销难以高效开展',
    content: `难以制定自动化培育成长路径，并自动化触达客户；\n客户触达效率低，且信息变更后无法自动调整营销策略`,
    icon: CasesPain3,
    id: 'cases-3',
    bgColor: 'yellow',
  },
  {
    title: '协同和赋能销售难以提效',
    content: `内容难以实时同步，无法了解销售查看/分发情况；\n市场线索到销售线索的过渡存在明显断层`,
    icon: CasesPain4,
    id: 'cases-4',
    bgColor: 'white',
  },
];

const stepData = {
  titles: [
    `电商数据补足画像，\n全面了解客户需求`,
    `多重信息组合筛选，\n智能细分客户群体`,
    `自动化实施差异营销，\n提升触达精准度和效率`,
    `通过内容中心和CRM协同销售，\n实现高效互通`,
  ],
  images: [Step1, Step2, Step3, Step4],
  classNames: ['', 'step_3_2', '', ''],
  contents: [
    [
      '客户除了在企业私域上的互动，也会在工业电商平台上活跃。例如，采购量不大的小型客户会定期在电商平台下单，中大型客户会在电商平台购买样品。',
      '电商数据能够帮助企业更好地了解小型客户的采买习惯、频次、周期，了解到中大型客户的大致需求，帮助销售在接触客户时找到更好的切入点。',
      '所以我们为企业提供对接工业电商平台的能力，捕捉商品信息、时间、规格、次数等数据，再结合我们为企业捕捉的企业私域数据，帮助企业更全面地了解客户。',
    ],
    [
      '以往客户的公司信息很难准确收集，我们为企业对接了完整的工商数据，客户在填写时可自动匹配公司全称，以及行业和规模等信息，为客户分群提供准确的信息基础。',
      '另外，结合我们在各渠道为企业收集到的客户属性和行为等基础信息，以及为客户标记的标签、阶段、分值等特征信息，企业可将特定的客户信息设定为判定规则，再通过且/或条件自由组合多条规则，实现智能、精细地客户分群。',
    ],
    [
      '对于不同类型的客户，企业市场部都会设定不同的营销策略。例如，小型客户需要洞察采购周期，定期为其推送优惠政策，促进复购。中大型客户，则时刻关注客户动态，需要设置重点营销事件的通知和流失预警提醒。',
      '我们为企业提供实时营销、主动营销、周期性营销、纪念日营销4种自动化触达方式，帮助企业用不同的营销流程覆盖全部客群。',
      '企业配置好营销流程后，若客户经过市场部的孵化后发生了类型变更，也能够自动进入新的营销流程，自动开启全新的成长路径。',
    ],
    [
      '在内容赋能层面，我们为企业提供从内容生产到流转的一站式内容中心，市场可在电脑端创建内容并创建分发任务。销售可在手机端查看工整清晰的内容资料库，接受分发任务后即可转发内容，并附带销售专属联系卡片。更有阅读排行，激励销售转发内容。',
      '在线索协同层面，我们为企业对接了CRM系统，市场在分配线索时，将自动在CRM系统中创建线索，并附带完整客户画像。销售跟进/退回线索后，也能实时反馈至市场使用的荟聚营销云。实现市场销售高效互通。',
    ],
  ],
};

export default () => (
  <Layout current="solutions" subCurrent="chemical-raw-materials">
    <Banner2
      title="化工原材料行业"
      Ptitle3="精细分层客群，营销精准覆盖"
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="咨询行业方案"
    />
    <TitledBoxesWithBg
      title="营销痛点与挑战"
      description="化工原材料行业的客户类型跨度大，且销售体系复杂。市场部需要对客群进行分层，制定不同的营销策略。市场部往往会遇到以下营销难题："
      boxDataList={boxDataList}
    />
    <StepSolutions data={stepData} stepsTopper={StepsTopper} />
  </Layout>
);
